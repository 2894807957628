import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { getApplicationViews } from 'services/tree.service';
import { collectionsStore, domainStore } from 'stores';

import DetailSelect from 'components/Collections/CollectionRow/DetailSelect';
import Select from 'components/Forms/Select';
import SimpleToggle from 'components/Forms/SimpleToggle';
import GhostLoader from 'components/Indicators/GhostLoader';

interface Props {
  activeMenu: string;
  collection: any;
  isDropped: boolean;
  setActiveMenu: Dispatch<SetStateAction<string>>;
  setIsDropped: Dispatch<SetStateAction<boolean>>;
}

function AdditionalFields(props: Props): JSX.Element {
  const { collection } = props;

  const [appViews, setAppViews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect((): void => {
    getApplicationViews(setAppViews).then(() => {
      setIsLoading(false);
    });
  }, []);

  function changeSearchable(isSearchable: boolean): void {
    collectionsStore.updateItem({
      body: { isSearchable },
      domainStore,
      id: collection.id,
    });
  }

  function handleChange(id: number): void {
    console.log('here: ', id);
    collectionsStore.updateItem({
      body: { viewId: id === 0 ? null : id },
      domainStore,
      id: collection.id,
    });
  }

  console.log('collection: ', toJS(collection));

  return (
    <div className="content-contain">
      <div>
        <div className="form-field-contain">
          <div className="form-field-label">Tags</div>
          <DetailSelect {...props} idList={'tagIds'} listType={'tags'} />
        </div>
        <div className="form-field-contain">
          <div className="form-field-label">View Mode</div>
          {isLoading && (
            <GhostLoader
              style={{ borderRadius: '2rem', height: '2rem' }}
              width="100%"
            />
          )}
          {!isLoading && (
            <Select
              handleChange={handleChange}
              options={appViews}
              selected={collection.viewId === undefined ? 0 : collection.viewId}
            />
          )}
        </div>
      </div>
      <div>
        <div className="form-field-contain d-flex align-items-center justify-content-between">
          <div className="form-field-label">unsearchable</div>
          <SimpleToggle
            didCheckOnLoad={props.collection.isSearchable}
            handleChange={changeSearchable}
          />
        </div>
      </div>
    </div>
  );
}

export default AdditionalFields;
